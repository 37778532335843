<template>
  <section v-if="data_loaded" class="terms-and-conditions" v-html="terms_and_conditions"/>
  <div v-else>
    <div v-for="(loading_el,index) in Array.from(new Array(20))" :key="index" class="element-loading" />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import { default_language } from "../../../../../../functions/constans/other"
import { legal_page_translations } from "../../../constants/endpoints"
import { CLIENT_STORE } from "../../../constants/other"
import { UPDATE_OTHER_PAGE_TRANSLATIONS } from "../../../stores/Client/constants"

export default {
  data() {
    return {
      data_loaded: false
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, ["other_page_translations", "selected_language"]),
    terms_and_conditions() {
      if (!this.other_page_translations) return ""

      return (
        this.other_page_translations[this.selected_language] ||
        this.other_page_translations[default_language] ||
        this.other_page_translations?.[Object.keys(this.other_page_translations)?.[0]] ||
        {}
      ).terms_and_conditions || ""
    }
  },
  watch: {
    selected_language() {
      this.fetch_translations()
    }
  },
  async mounted() {
    if (!this.terms_and_conditions) this.fetch_translations()
    else this.data_loaded = true
  },
  methods: {
    ...mapMutations(CLIENT_STORE, {
      update_legal_page_translations: UPDATE_OTHER_PAGE_TRANSLATIONS
    }),
    async fetch_translations() {
      this.data_loaded = false

      const { data } = await legal_page_translations({ pageType: "terms_and_conditions", language: this.selected_language })
      this.update_legal_page_translations({
        type: "terms_and_conditions",
        language: data.language,
        translations: data.translations
      })

      this.data_loaded = true
    }
  }
}
</script>
