<template>
  <div class="legal-page">
    <privacy_policy v-if="get_correct_page === 'privacy-policy'"/>
    <return_policy v-if="get_correct_page === 'return-policy'"/>
    <terms_and_conditions v-if="get_correct_page === 'terms-and-conditions'"/>
  </div>
</template>

<script>
import privacy_policy from "../utils/legal-pages/privacy-policy"
import return_policy from "../utils/legal-pages/return-policy"
import terms_and_conditions from "../utils/legal-pages/terms-and-conditions"

export default {
  components: {
    privacy_policy,
    return_policy,
    terms_and_conditions
  },
  data() {
    return {
      default_page: "privacy-policy"
    }
  },
  computed: {
    get_correct_page() {
      if(this.$route.params.subtype !== "legal-page")
        return this.$route.params.subtype
      else return this.default_page
    }
  },
};
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/legal_pages";
@use "../../../../styles/element_loading";

.legal-page {
  a {
    color: var(--font_heavy);
  }

  .element-loading {
    height: 20px;
    margin-bottom: 10px;

    &:first-child {
      height: 40px;
      margin: 30px 0 20px;
      width: 300px;
    }
    &:nth-child(6n) {
      width: 80%;
    }
    &:nth-child(4n) {
      width: 70%;
    }
  }
}
</style>
